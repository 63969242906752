<template>
  <!-- 结算管理 - 开票单位管理 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">开票管理列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="发票类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">发票类型:</span>
              <el-select
                clearable
                placeholder="请选择票据类型"
                size="small"
                v-model="retrievalData.invoiceType"
              >
                <el-option
                  size="small"
                  v-for="item in invoice_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="addEdit"
                >新增</el-button
              >
              <!-- <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                style="margin-right: 22px;"
                round
                @click="IssuingOfficeUpload()"
                >开票单位上传</el-button
              > -->
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              /><el-table-column
                label="申请日期"
                align="center"
                show-overflow-tooltip
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="开票状态"
                align="center"
                show-overflow-tooltip
                prop="invoiceState"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("INVOICE_STATE", scope.row.invoiceState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="发票类型"
                align="center"
                show-overflow-tooltip
                prop="invoiceType"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("INVOICE_TYPE", scope.row.invoiceType) }}
                </template>
              </el-table-column>
              <el-table-column
                label="发票金额"
                align="center"
                show-overflow-tooltip
                prop="invoiceMoney"
              />
              <el-table-column
                label="开票日期"
                align="center"
                show-overflow-tooltip
                ><template slot-scope="scope">{{
                  scope.row.createTime | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="开票方"
                align="center"
                show-overflow-tooltip
                prop="openCompany"
              />
              <el-table-column
                label="发票编号"
                align="center"
                show-overflow-tooltip
                prop="invoiceNo"
              />
              <el-table-column
                label="备注"
                align="center"
                show-overflow-tooltip
                prop="remark"
              />
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="lookEdit(scope.row.invoiceId)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="
                      InvoiceDelete(scope.row.invoiceId, scope.row.invoiceState)
                    "
                    >删除</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增 - 弹框 -->
    <el-dialog
      title="新增"
      :visible.sync="rejectzledialog"
      top="2%"
      @close="addNo"
      :close-on-click-modal="false"
      ><div class="ovy-a">
        <h3>1.选择订单</h3>
        <el-table
          ref="multipleTable"
          :data="tableOrderData"
          size="small"
          tooltip-effect="dark"
          row-key="orderId"
          style="width: 100%"
          @selection-change="checkListData"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            type="selection"
            width="50px"
            align="center"
            fixed
            :reserve-selection="true"
            :selectable="selectable"
          ></el-table-column>
          <el-table-column
            label="序号"
            align="left"
            type="index"
            :index="indexMethod"
            fixed
            width="60px"
          />
          <el-table-column
            label="订单编号"
            align="left"
            show-overflow-tooltip
            prop="orderSn"
          />
          <el-table-column
            label="支付订单编号"
            align="left"
            show-overflow-tooltip
            prop="orderPaymentNumber"
          />
          <el-table-column
            label="支付类型"
            align="left"
            show-overflow-tooltip
            prop="paymentMethod"
          >
            <template slot-scope="scope">
              {{ $setDictionary("PAYMENT_METHOD", scope.row.paymentMethod) }}
            </template>
          </el-table-column>
          <el-table-column
            label="订单总额"
            align="left"
            show-overflow-tooltip
            prop="paymentMoney"
          />

          <el-table-column
            label="支付时间"
            align="left"
            prop="payTime"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.payTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100px"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="lookInvoice(scope.row.orderId)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
        <h3>2.填写发票信息</h3>
        <el-form
          :model="rejectzDta"
          :rules="rules"
          ref="rejectzDta"
          label-width="120px"
          class="demo-ruleForm basicData"
        >
          <el-form-item label="发票类型" prop="invoiceType">
            <el-select
              clearable
              placeholder="请选择票据类型"
              size="small"
              v-model="rejectzDta.invoiceType"
            >
              <el-option
                size="small"
                v-for="item in invoice_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收票单位" prop="closeCompany">
            <el-input
              clearable
              placeholder="请输入收票单位"
              size="small"
              v-model="rejectzDta.closeCompany"
            ></el-input>
          </el-form-item>
          <el-form-item label="税号" prop="taxNo">
            <el-input
              clearable
              placeholder="请输入税号"
              size="small"
              v-model="rejectzDta.taxNo"
            ></el-input>
          </el-form-item>

          <el-form-item label="开户行及账号" prop="accountNumber">
            <el-input
              clearable
              placeholder="请输入开户行及账号"
              size="small"
              v-model="rejectzDta.accountNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址、电话" prop="addressMobile">
            <el-input
              clearable
              placeholder="请输入地址、电话"
              size="small"
              v-model="rejectzDta.addressMobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              clearable
              placeholder="请输入邮箱"
              size="small"
              v-model="rejectzDta.email"
            ></el-input>
          </el-form-item>
          <p style="padding-left: 8rem">
            目前平台开票内容只支持<span style="color: red">课程服务费</span>。
          </p>
          <el-form-item label="开票备注" prop="invoiceExplain">
            <el-input
              clearable
              placeholder="请输入开票备注"
              size="small"
              v-model="rejectzDta.invoiceExplain"
            ></el-input>
          </el-form-item>
          <el-form-item label="开票金额" prop="invoiceMoney">
            <el-input
              clearable
              placeholder="请输入开票金额"
              size="small"
              disabled
              v-model="rejectzDta.invoiceMoney"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="addNo">取 消</el-button>
        <el-button @click="addOk" class="bgc-bv" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看 - 弹框 -->
    <el-dialog
      title="查看"
      :visible.sync="lookOrderdialog"
      top="2%"
      @close="lookOrderNo"
      append-to-body
    >
      <div class="ovy-a">
        <h3>发票信息</h3>
        <el-form
          :model="rejectzDta"
          :rules="rules"
          disabled
          ref="rejectzDta"
          label-width="120px"
          class="demo-ruleForm basicData"
        >
          <el-form-item label="申请日期" prop="createTime">
            <el-input
              clearable
              placeholder="请输入申请日期"
              size="small"
              v-model="rejectzDta.createTime"
            ></el-input>
          </el-form-item>
          <el-form-item label="发票类型" prop="invoiceType">
            <el-select
              clearable
              placeholder="请选择票据类型"
              size="small"
              v-model="rejectzDta.invoiceType"
            >
              <el-option
                size="small"
                v-for="item in invoice_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收票单位" prop="closeCompany">
            <el-input
              clearable
              placeholder="请输入收票单位"
              size="small"
              v-model="rejectzDta.closeCompany"
            ></el-input>
          </el-form-item>
          <el-form-item label="税号" prop="taxNo">
            <el-input
              clearable
              placeholder="请输入税号"
              size="small"
              v-model="rejectzDta.taxNo"
            ></el-input>
          </el-form-item>

          <el-form-item label="开户行及账号" prop="accountNumber">
            <el-input
              clearable
              placeholder="请输入开户行及账号"
              size="small"
              v-model="rejectzDta.accountNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址、电话" prop="addressMobile">
            <el-input
              clearable
              placeholder="请输入地址、电话"
              size="small"
              v-model="rejectzDta.addressMobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              clearable
              placeholder="请输入邮箱"
              size="small"
              v-model="rejectzDta.email"
            ></el-input>
          </el-form-item>
          <p style="padding-left: 8rem">
            目前平台开票内容只支持<span style="color: red">课程服务费</span>。
          </p>
          <el-form-item label="开票备注" prop="invoiceExplain">
            <el-input
              clearable
              placeholder="请输入开票备注"
              size="small"
              disabled
              v-model="rejectzDta.invoiceExplain"
            ></el-input>
          </el-form-item>
          <el-form-item label="开票金额" prop="invoiceMoney">
            <el-input
              clearable
              placeholder="请输入开票金额"
              size="small"
              disabled
              v-model="rejectzDta.invoiceMoney"
            ></el-input>
          </el-form-item>
          <el-form-item label="开票状态" prop="invoiceMoney">
            <el-select
              clearable
              placeholder="请选择票据类型"
              size="small"
              v-model="rejectzDta.invoiceState"
            >
              <el-option
                size="small"
                v-for="item in invoice_state"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票编号" prop="invoiceNo">
            <el-input
              clearable
              placeholder="请输入发票编号"
              size="small"
              disabled
              v-model="rejectzDta.invoiceNo"
            ></el-input>
          </el-form-item>
        </el-form>
        <h3>开票明细</h3>
        <el-table
          ref="multipleTable"
          :data="rejectzDta.orderList"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="left"
            type="index"
            :index="indexMethod"
            fixed
            width="60px"
          />
          <el-table-column
            label="订单编号"
            align="left"
            show-overflow-tooltip
            prop="orderSn"
          />
          <el-table-column
            label="支付订单编号"
            align="left"
            show-overflow-tooltip
            prop="orderPaymentNumber"
          />
          <el-table-column
            label="支付类型"
            align="left"
            show-overflow-tooltip
            prop="paymentMethod"
          >
            <template slot-scope="scope">
              {{ $setDictionary("PAYMENT_METHOD", scope.row.paymentMethod) }}
            </template>
          </el-table-column>
          <el-table-column
            label="订单总额"
            align="left"
            show-overflow-tooltip
            prop="paymentMoney"
          />

          <el-table-column
            label="支付时间"
            align="left"
            prop="payTime"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.payTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100px"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="lookInvoice(scope.row.orderId)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
        <h3 style="margin-top: 10px" v-if="rejectzDta.vouchers">
          开票方上传凭证
        </h3>
        <div
          style="display: flex; flex-direction: column"
          v-if="rejectzDta.vouchers"
        >
          <span
            style="color: #409eff; margin-top: 10px"
            v-for="(item, index) in rejectzDta.vouchers"
            :key="index"
          >
            {{ item.voucherName }}
            <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
            <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px"
              >预览</a
            >
          </span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="lookOrderNo">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 查看订单信息 - 弹框 -->
    <el-dialog title="订单信息" :visible.sync="invoicedialog" top="10%"
      ><div class="ovy-a">
        <el-descriptions title="订单详情">
          <el-descriptions-item label="订单编号:">{{
            orderInfo.orderSn
          }}</el-descriptions-item>
          <el-descriptions-item label="订单总额:">{{
            orderInfo.paymentMoney
          }}</el-descriptions-item>
          <el-descriptions-item label="总数量:">{{
            orderInfo.totalCount
          }}</el-descriptions-item>
          <el-descriptions-item label="总课时:">{{
            orderInfo.lessonNum
          }}</el-descriptions-item>
          <el-descriptions-item label="支付时间:">{{
            orderInfo.payTime | moment
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间:">{{
            orderInfo.createTime | moment
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="课程信息" style="margin-top: 15px">
          <el-descriptions-item label="课程名称:">{{
            orderInfo.courseInfoList
              ? orderInfo.courseInfoList[0].productName
              : ""
          }}</el-descriptions-item>
          <el-descriptions-item label="课程单价:"
            >{{
              orderInfo.courseInfoList
                ? orderInfo.courseInfoList[0].productPrice
                : ""
            }}元</el-descriptions-item
          >
          <el-descriptions-item label="总数量:">{{
            orderInfo.courseInfoList
              ? orderInfo.courseInfoList[0].productCnt
              : ""
          }}</el-descriptions-item>
          <el-descriptions-item label="金额:"
            >{{
              orderInfo.courseInfoList
                ? orderInfo.courseInfoList[0].totalPrice
                : ""
            }}元</el-descriptions-item
          >
          <el-descriptions-item label="订单状态:">{{
            $setDictionary(
              "ORDER_STATUS",
              orderInfo.courseInfoList
                ? orderInfo.courseInfoList[0].orderStatus
                : ""
            )
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="orderInfoNo">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "InvoicingCompanyManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    var checkName = (rule, value, callback) => {
      if (value) {
        this.$post("/biz/new/bill/invoice/check", { companyName: value }).then(
          (res) => {
            if (res.status == "0") {
              if (!res.data.isExist) {
                return callback(new Error("该公司(企业)不存在"));
              }
            }
          }
        );
      } else {
        return callback(new Error("请输入收票单位"));
      }
    };
    return {
      // 检索数据集
      retrievalData: {
        // 单位名称
        invoiceType: "",
      },
      // 收票单位 - 下拉数据
      CompanyList: [],
      // 新增/编辑 - loading
      rejectzledialog: false,
      // 新增/编辑 - 数据
      rejectzDta: {
        // 收票单位
        closeCompanyId: "",
        // 收票单位
        closeCompany: "",
        // 税号
        taxNo: "",
        // 地址、电话
        addressMobile: "",
        // 开户行及账号
        accountNumber: "",
        // 邮箱
        email: "",
        //开票备注
        invoiceExplain: "",
        //开票金额
        invoiceMoney: "",
      },
      // 基础数据集 - 数据校验
      rules: {
        //发票类型
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
        // 收票单位
        closeCompany: [
          { required: true, message: "请输入收票单位", trigger: "blur" },
          // { required: true, validator: checkName, trigger: "blur" },
        ],
        // 税号
        taxNo: [{ required: true, message: "请输入税号", trigger: "blur" }],
        // 地址、税号
        addressMobile: [
          { required: true, message: "请输入地址、税号", trigger: "blur" },
        ],
        // 开户行及账号
        accountNumber: [
          { required: true, message: "请输入开户行及账号", trigger: "blur" },
        ],
        // 邮箱
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
      },
      // 订单数据列表
      tableOrderData: [],
      //订单信息dialog
      invoicedialog: false,
      //订单信息 -- 数据
      orderInfo: {},
      //订单选中的数据
      multipleTable: [],
      invoice_type: [],
      //查看发票信息
      lookOrderdialog: false,
      //开票状态下拉数据
      invoice_state: [],
      previewLoding1: false,
      ImgSrc: "",
    };
  },
  watch: {
    // "rejectzDta.closeCompany": {
    //   handler: (val,val1)=> {
    //     console.log(val)
    //     console.log(this);
    //   },
    // },
  },
  created() {
    this.getCompanyList();
    this.getInvoice_type();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    // 获取数据 - 收票单位
    getCompanyList(query) {
      this.$post("/biz/new/bill/invoice/company/pageList", {
        closeCompany: query,
      })
        .then((res) => {
          if (res.status == 0) {
            this.CompanyList = res.data.list || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 选中 - 收票单位 - 获取赋值name
    bindChange(val) {
      let obj = {};
      obj = this.CompanyList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.closeCompanyId === val; //筛选出匹配数据
      });
      this.retrievalData.closeCompany = obj.closeCompany;
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
      };
      // 开票单位
      if (this.retrievalData.invoiceType) {
        params.invoiceType = this.retrievalData.invoiceType;
      }
      this.doFetch({
        url: "/biz/new/bill/invoice/hospital/pageList",
        params,
        pageNum,
      });
    },
    getOrderList() {
      this.$post("/biz/invoice/order/orderList").then((ret) => {
        this.tableOrderData = ret.data || [];
      });
    },
    // 添加
    addEdit(row) {
      this.rejectzledialog = true;
      this.$refs.multipleTable.clearSelection();
      this.multipleTable = [];
      console.log(this.multipleTable);
      this.getOrderList();
      this.getUserInfoFill();
    },
    // 添加
    lookEdit(invoiceId) {
      this.lookOrderdialog = true;
      this.$post("/biz/new/bill/invoice/hospital/getInfo", { invoiceId }).then(
        (res) => {
          this.rejectzDta = {
            ...res.data,
          };
        }
      );
    },
    lookOrderNo() {
      this.lookOrderdialog = false;
      this.rejectzDta = {
        invoiceMoney: "",
      };
    },
    // 新增/编辑 - 取消
    addNo() {
      // Object.keys(this.rejectzDta).forEach((key) => {
      //   this.rejectzDta[key] = "";
      // });
      this.rejectzledialog = false;
      const el = this.$refs["rejectzDta"];
      el.clearValidate();
      this.$refs.multipleTable.clearSelection();
    },
    // 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
      console.log(this.multipleTable);
      let sum = 0;
      for (let index = 0; index < this.multipleTable.length; index++) {
        sum += Number(this.multipleTable[index].paymentMoney);
      }
      this.rejectzDta.invoiceMoney = sum.toFixed(2);
      if (!this.multipleTable.length) {
        this.rejectzDta.invoiceMoney = 0;
      }
    },
    // 新增/编辑 - 确定
    addOk() {
      if (this.multipleTable.length > 0) {
        let billNodes = [];
        this.multipleTable.map((t) => {
          billNodes.push({
            orderId: t.orderId,
            orderSn: t.orderSn,
            paymentMoney: t.paymentMoney,
            payTime: t.payTime,
          });
        });
        const el = this.$refs["rejectzDta"];
        el.clearValidate("compIdcard");
        el.validate((valid) => {
          if (valid) {
            let params = {
              ...this.rejectzDta,
              billNodes,
            };
            this.$post(
              "/biz/new/bill/invoice/hospital/saveOrUpdate",
              params
            ).then((res) => {
              if (res.status == 0) {
                this.getData();
                Object.keys(this.rejectzDta).forEach((key) => {
                  this.rejectzDta[key] = "";
                });
                this.$message({
                  type: "success",
                  message: this.rejectzDta.closeCompanyId
                    ? "编辑成功"
                    : "新增成功",
                });
                this.rejectzledialog = false;
                this.$refs.multipleTable.clearSelection();
              }
            });
          }
        });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    //查看订单信息
    lookInvoice(orderId) {
      this.$post("/biz/invoice/order/getInfo", { orderId }).then((res) => {
        if (res.status == 0) {
          this.orderInfo = {
            ...res.data,
          };
          this.invoicedialog = true;
        }
      });
    },

    orderInfoNo() {
      this.invoicedialog = false;
      this.orderInfo = {};
    },
    getUserInfoFill() {
      this.$post("/biz/new/bill/invoice/hospital/filling").then((res) => {
        this.rejectzDta = {
          ...this.rejectzDta,
          ...res.data,
        };
      });
    },
    // 获取字典 - 票据种类 - 下拉数据
    getInvoice_type() {
      let arr = this.$setDictionary("INVOICE_TYPE", "list");
      let arr2 = this.$setDictionary("INVOICE_STATE", "list");
      for (const key in arr) {
        this.invoice_type.push({
          label: arr[key],
          value: key,
          disabled: key == 10 || key == 20 ? true : false,
        });
      }
      for (const key in arr2) {
        this.invoice_state.push({
          label: arr2[key],
          value: key,
        });
      }
    },
    //删除
    InvoiceDelete(invoiceId, invoiceState) {
      this.doDel({
        url: "/biz/new/bill/invoice/hospital/delete",
        msg: "是否确认删除？",
        ps: {
          type: "post",
          data: { invoiceId, invoiceState },
        },
      });
    },
    lookExl(item) {
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      console.log("item.fileType", item.fileType);
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.voucherUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = item.voucherUrl;
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.bh /deep/ .el-textarea__inner {
  height: 200px;
}
.el-descriptions /deep/ .el-descriptions__title {
  font-size: 1.25rem;
}
.el-descriptions /deep/ .el-descriptions-item__container {
  font-size: 1rem;
}
.el-descriptions
  .el-descriptions__body
  .el-descriptions__table
  /deep/
  .el-descriptions-row {
  margin-top: 10px;
}
</style>
